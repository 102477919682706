<template>
    <div id="test">
        <el-button type="primary" @click="info">添加</el-button>
    </div>
</template>
<script>
    import axiosCB from '@/axios/indexCB'
    export default {
        data() {
            return {

            }
        },
        async mounted() {

        },
        methods: {
            info() {
                axiosCB.request({
                    url: `/network/zmchain/sendRawTransaction`,
                    method: "POST",
                    data: {
                        "chaincodeName": "evidence",
                        "functionName": "newEvidence",
                        "params": [JSON.stringify({ "title": "我是好人", "img": "https://tracepic.eqscloud.com/1/field/cdd3e068fef94541.jpg", "txt": "柳暗花明又一村", "video": "http://124.222.245.15:2022/video1.mp4" })],
                        "signatureAccount": "xpw",
                        "recordType": "测试打卡记录",
                        "dataType": 0,
                        "isEncrypted": 0
                    }
                })
            }

        }
    }
</script>
<style scoped lang="less">
</style>