import axios from 'axios'
import Vue from "vue";
import { Message } from 'element-ui'
const http = axios.create({
    headers: {},
    baseURL: "https://zmchain-api.zmsaas.cn/"
    // baseURL: "http://47.92.31.124:3000/"
})


// 添加请求拦截器
http.interceptors.request.use(config => {
    // 在发送请求之前做些什么
    return config;
}, error => {
    console.log("触发请求拦截错误");
    return Promise.reject(error);
});

// 添加响应拦截器
http.interceptors.response.use(response => {
    if (response.data.code !== 0) {
        // Message.error(response.data.message)
        // Message.error(`接口数据响应错误\nApi：${response.config.url}\n错误代码：${response.data.code}\n错误消息：${response.data.message}`)
        // Vue.$toast.error(`接口数据响应错误\nApi：${response.config.url}\n错误代码：${response.data.code}\n错误消息：${response.data.message}`)
        return Promise.reject(response.data);
    } else {
        return response.data;
    }
}, error => {
    if (error.config.config?.errorFalg) return Promise.reject(error);//不显示全局消息
    // response.status超出 2xx 范围的状态码都会触发该函数。
    if (error.config && error.config.url) {
        // Message.error(error.response.data.message)
        // Message.error(`请求接口数据响应错误\nApi：${error.config.url}\n错误代码：${error.response.data.code}\n错误消息：${error.response.data.message}`)
        // Vue.$toast.error(`请求接口数据响应错误\nApi：${error.config.url}\n错误代码：${error.response.data.code}\n错误消息：${error.response.data.message}`)
    } else {
        // Vue.$toast.error(`请求服务器错误`)
    }
    return Promise.reject(error);
});

export default http